import React from "react";
import NumberFormat from "react-number-format";
import { formatNumberWithDollar } from "../utils/numberFormater";

const PageHeaderComponent = ({
  name = "",
  value = 0,
  percent = 0,
  displayNumber = true,
}) => {
  return (
    <div className="d-flex align-items-center">
      <div className="d-flex align-items-end">
        <div className="header-component-text">{name}</div>
        <div
          className={`header-component-value ${!displayNumber && `d-none`} `}
        >
          {formatNumberWithDollar(value)}
        </div>
        {percent > 0 && (
          <>
            <sup className="header-percent">
              <NumberFormat
                value={percent}
                displayType="text"
                suffix="%"
                prefix={percent > 0 ? "+" : ""}
              />
            </sup>
            <span className="header-percent">
              <NumberFormat
                value={percent}
                displayType="text"
                suffix="%"
                prefix={percent > 0 ? "+" : ""}
              />
            </span>
          </>
        )}
      </div>
    </div>
  );
};

PageHeaderComponent.propTypes = {};

export default PageHeaderComponent;
