import { CircularProgress } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";
import { ESTIMATION_TYPES, TIME_FILTER_INDICES } from "../../../commons/enum";
import MixedChartComponent from "../../../components/mixed-chart-component";
import PageHeaderComponent from "../../../components/page-header-component";
import { useIsGettingFinance } from "../../../hooks/account.hooks";
import {
  usePropertyList,
  useTotalMarketIndices,
  useTotalPropertyDebtData,
  useTotalPropertyDetail,
} from "../../../hooks/property.hooks";
import AddPropertyButton from "../../../features/property/add-property-button";

const PropertyChartContainer = () => {
  const gettingFinance = useIsGettingFinance();
  const {
    data: propertyList,
    isLoading: isLoadingPropertyDetail,
  } = usePropertyList();
  const totalMarketIndices = useTotalMarketIndices();
  const totalPropertyDebtData = useTotalPropertyDebtData();
  const totalPropertyDetail = useTotalPropertyDetail();
  const { history: totalDebtHistory = {} } = totalPropertyDebtData;

  return (
    <>
      {!gettingFinance ? (
        <>
          {isLoadingPropertyDetail ? (
            <div className="da-connecting-data">
              <div className="connecting-data">
                <div className="connecting-content">Connecting Data</div>
                <CircularProgress
                  color="inherit"
                  className="connecting-spinner"
                />
              </div>
            </div>
          ) : (
            <>
              {propertyList?.length === 0 && (
                <div className="da-header-container">
                  <PageHeaderComponent
                    name="No Properties Linked"
                    displayNumber={false}
                  />
                  <div className="da-add-property-button">
                    <AddPropertyButton />
                  </div>
                </div>
              )}
              <div className="dashboard-property-chart__container">
                <div className="da-property-bar-chart-container">
                  <MixedChartComponent
                    hideChartFilter
                    defaultEstimationType={ESTIMATION_TYPES.PROPERTY_PORTFOLIO}
                    defaultTimeFilterIndex={TIME_FILTER_INDICES.ONE_YEAR}
                    propertyEstimateHistory={totalPropertyDetail}
                    saleHistoryChartData={totalMarketIndices}
                    debtChartData={totalDebtHistory}
                  />
                </div>
                {propertyList?.length === 0 && <div className="nodata-img" />}
              </div>
            </>
          )}
        </>
      ) : (
        <Skeleton animation="wave" variant="rect" width="100%" height={300} />
      )}
    </>
  );
};

PropertyChartContainer.propTypes = {};

export default React.memo(PropertyChartContainer);
